import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export const fetchNamesBases = createAsyncThunk(
    'stateNames/get',
    async () => {
        let response = '';
            response = await axios.post(serverUrl + 'bases/base-names/Get');
        return response.data; //data['?'] data.sda? or response two var and parse action
    }
)

const NetworkNameBases = createSlice({
    name: 'stateNames',
    initialState: {
        data: '[]',
        status: []
    },
    extraReducers: {
        [fetchNamesBases.pending]: (state) => {
            state.status = 'loading';
        },
        [fetchNamesBases.rejected]: (state, action) => {
            state.status = 'error';
            //state.data = [JSON.stringify(action.payload)];
            console.log('err', state)
        },
        [fetchNamesBases.fulfilled]: (state, action) => {
            state.data = JSON.stringify(action.payload);
            state.status = 'complete';
        }
    }
})

export const { getLocalNames } = NetworkNameBases.actions;
export default NetworkNameBases.reducer;