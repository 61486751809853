import { configureStore } from "@reduxjs/toolkit";
import searchSelectReducer  from "../searchTypeSlice";
import networkBinReducer from "../getNetworkBinSlice";
import getNetworkBaseNamesReducer  from "../getNetworkBaseNamesSlice";
import tableNameSelectReducer  from "../selectBaseNameSlice";

export default configureStore({
    reducer: {
        valuesSrcType: searchSelectReducer,
        valuesBins: networkBinReducer,
        valuesNameBases: getNetworkBaseNamesReducer,
        valuesNameBaseSelect: tableNameSelectReducer,
    }
})