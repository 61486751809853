import { createSlice } from "@reduxjs/toolkit";


const selectBaseNameSlice = createSlice({
    name: 'selectedbase',
    initialState: {
        selects: ''
    },
    reducers: {
        nameSelect(state, action) {
            state.selects = action.payload;
        }
    }
})

export const { nameSelect } = selectBaseNameSlice.actions;
export default selectBaseNameSlice.reducer;