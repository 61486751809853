import * as React from 'react';
import Radio from '@mui/material/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { selectType } from '../../redux/searchTypeSlice';

export default function CheckBoxType() {
    const dispatch = useDispatch();
    const srcData = useSelector(state => state?.valuesSrcType?.type);

    const handleChange = (event) => {
        const value = event.target.value;
        dispatch(selectType(value));
    };
    return (
        <div className='check_box'>
            <Radio
                checked={srcData === 'B'}
                onChange={handleChange}
                value="B"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'B' }}
            />
            <Radio
                checked={srcData === 'Z'}
                onChange={handleChange}
                value="Z"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'Z' }}
            />
        </div>
    );
}