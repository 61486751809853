import { createSlice } from "@reduxjs/toolkit";

const searchTypeSlice = createSlice({
    name: 'srcSelect',
    initialState: {
        type: 'B'
    },
    reducers: {
        selectType(state, action) {
            state.type = action.payload;
        }
    }
})

export const { selectType } = searchTypeSlice.actions;
export default searchTypeSlice.reducer;