import * as React from 'react';
import SearchBarPanel from "../searchbar/SearchBarPanel";
import ViewPaginationTable from "./components/ViewPaginationTable";
import LoadingTables from './components/LoadingTables';
import { useSelector } from 'react-redux';
import HelpButton from './components/HelpButton';

export default function CenterPage() {
    const [toTabData, setToTabData] = React.useState([]);
    const data = useSelector(state => state?.valuesBins?.data);
    return (
        <>
            <SearchBarPanel setrows={setToTabData} rowsdata={toTabData} />
            {data?.length ?
                <ViewPaginationTable setrows={setToTabData} rowsdata={toTabData} />
                : <LoadingTables />}
            <HelpButton />
        </>
    )
}