import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HelpIcon from '@mui/icons-material/Help';
import example from '../../static/example.png';
import { Link } from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    //   border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function HelpButton() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            <Button onClick={handleOpen} sx={{ marginTop: 2, marginLeft: 2, position: "absolute", right: 0, display: 'block', top: 0 }}>
                <HelpIcon sx={{ color: 'white', fontSize: 40 }} />
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        FINSON STORE HELP
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        На панели после кнопки раскрытия зипов - выбор базы для поиска.<br />
                        Поиск умолчанию идет по BIN (поддерживает множество значений через пробел)<br />
                        Например:<br />
                        <img src={example} /><br />
                        Таким же образом идет поиск по ZIP.<br /><p />
                        Наши контакты:<br />
                        <Link href='https://t.me/finsonseller'>https://t.me/finsonseller</Link><br />
                        <Link href='https://t.me/+Yij8YXvjB8E4ZjVi'>https://t.me/+Yij8YXvjB8E4ZjVi</Link>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}