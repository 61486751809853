import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const serverUrl = process.env.REACT_APP_SERVER_URL;

export const fetchBinZips = createAsyncThunk(
    'stateStrings/get',
    async ({name_base}) => {
        let response = '';
        let headers = { }
            response = await axios.post(serverUrl + 'bases/GetBinZipInfoLend', {name_base}, headers);
        return response; 
    }
)

const stateNetworkStringsSlice = createSlice({
    name: 'stateStrings',
    initialState: {
        data: {},
        status: []
    },
    extraReducers: {
        [fetchBinZips.pending]: (state) => {
            state.status = 'loading';
        },
        [fetchBinZips.rejected]: (state, action) => {
            state.status = 'error';
            state.data = [JSON.stringify(action.payload?.data)];
        },
        [fetchBinZips.fulfilled]: (state, action) => {
            state.data = action.payload.data;
            state.status = 'complete';
        }
    }
})

export const { actionsBinZips } = stateNetworkStringsSlice.actions;
export default stateNetworkStringsSlice.reducer;