import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { useDispatch, useSelector } from 'react-redux';
import { nameSelect } from '../../redux/selectBaseNameSlice';

export default function SelectBase() {
  const dispatch = useDispatch();
  const datanames = useSelector(state => state?.valuesNameBases.data);

  function handleChange(e) {
    if (e.target.value == "<-all->") {
      dispatch(nameSelect({ name: "<-all->" }))
    } else {
      const selectedBase = JSON.parse(datanames).filter((base, idx) => {
        return idx == e.target.value;
      })
      dispatch(nameSelect({ name: selectedBase[0]?.name }))
    }
  }
  return (
    <Box sx={{ minWidth: 120, marginRight: 4, marginTop: 1 }}>
      <FormControl fullWidth>
        {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Base
        </InputLabel> */}
        <NativeSelect
          defaultValue={"<-all->"}
          onChange={e => handleChange(e)}
          inputProps={{
            name: 'age',
            id: 'uncontrolled-native',
          }}
        >
          <option value={"<-all->"} key={0}>{"<-all->"}</option>
          {JSON.parse(datanames).map((name, idx) => {
            return <option value={idx} key={idx++}>{name?.name_pub}</option>
          })}
        </NativeSelect>
      </FormControl>
    </Box>
  );
}