import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CheckBoxType from './components/CheckBoxType';
import SelectBase from './components/SelectBase';
import LoadingButtons from './components/LoadingButtons';
import PageviewIcon from '@mui/icons-material/Pageview';

export default function SearchBarPanel({ setrows, rowsdata }) {
  const srcType = useSelector(state => state?.valuesSrcType?.type);
  const nameBaseSelect = useSelector(state => state?.valuesNameBaseSelect?.selects?.name);
  const data = useSelector(state => state?.valuesBins?.data);
  const datanames = useSelector(state => state?.valuesNameBases.data);

  const [searchedStr, setSearchedStr] = React.useState('');

  function createData(bin, zips_count, zips) {
    return {bin, zips_count, zips: zips};
}

  function handleSearchInput() {
    const toTabData = data ? data.map(bin => {
      return createData(bin.bin, bin.zips?.length, bin.zips)
  }) : [];
    //---
    function checkZip(arr, regexp) {
      let check = false;
      arr.forEach(zip => {
          if(zip.zip?.match(regexp)) check = true;
        })
        return check;
    }
    const searchTerms = searchedStr.split(' ');
    const filteredRows = toTabData.filter((string) => {
      for (let src in searchTerms) {
        const regExp = new RegExp('^' + searchTerms[src], 'g');
        if (srcType == 'B' ? string.bin.match(regExp) : checkZip(string.zips, regExp)) {
          return string;
        }
      }
    });
    setrows(filteredRows);
  }

  function handleClearSearch() {
    const toTabData = data ? data.map(bin => {
      return createData(bin.bin, bin.zips?.length, bin.zips)
  }) : [];
    setSearchedStr('');
    setrows(toTabData);
  }

  function expandClickRows() {
    const arrButtons = document.getElementsByClassName('rowss');
    for(let but of arrButtons) {
        but.click();
    }
}
  return (
    <div className='search_bar'>
      <Button className='button-expand' onClick={expandClickRows} variant="text" sx={{ marginTop: 1, marginRight: 2}} >
            <PageviewIcon />
        </Button>
      {JSON.parse(datanames)?.length ? <SelectBase /> : <LoadingButtons/>}
        <CheckBoxType />
      <TextField
        fullWidth
        value={searchedStr}
        style={{ marginTop: 9, width: '37%' }}
        color="secondary"
        // focused 
        size="small"
        //inputProps={{ type: "search" }}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            {searchedStr ? <IconButton
              onClick={() => handleClearSearch()} >
              <ClearIcon />
            </IconButton> : null}
          </InputAdornment>
        }}
        id="outlined-basic"
        //label="Outlined"
        label={srcType === 'B' ? 'SEARCH BIN "379364 406587 ..."' : 'SEARCH ZIP "85296:83642 ...'}
        variant="outlined"
        onInput={event => setSearchedStr(event.target.value)}
      />
      <Button variant="text" onClick={() => handleSearchInput()} style={{ marginTop: 8, marginLeft:5, marginRight: 5}}>SEARCH</Button>
    </div>
  );
}
