import * as React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';


export default function RowBin(props) {
    const { row, opens } = props;
    const [open, setOpen] = React.useState(opens);
  
    return (
      <React.Fragment >
        <TableRow sx={{ '& > *': { borderBottom: 'unset',}, '& .MuiTableCell-root': {
           height: '10px',
           padding: '3px',
           paddingRight: '10px'
        }, }}>
          <TableCell sx={{width: 0.01,}}>
            <IconButton
              aria-label="expand row"
              size="small"
              className='rowss'
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" >
            {row.bin}
          </TableCell>
          <TableCell align="right">{row.zips_count}</TableCell>
          
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: '#b8c0cf' }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h7" gutterBottom component="div">
                  Aviliable zip's
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    {/* <TableRow>
                      <TableCell>ZIP</TableCell>
                    </TableRow> */}
                  </TableHead>
                  <TableBody>
                    {row.zips.map((str, idx) => (
                      <TableRow key={idx}>
                        <TableCell component="th" scope="row">
                          {str.zip}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  RowBin.propTypes = {
    row: PropTypes.shape({
      bin: PropTypes.string.isRequired,
      zips_count: PropTypes.number.isRequired,
      zips: PropTypes.arrayOf(
        PropTypes.shape({
          zip: PropTypes.string.isRequired,
          
        }),
      ).isRequired,
    }).isRequired,
  };