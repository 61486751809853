import './App.css';
import { useEffect } from 'react';
import CenterPage from './table/CenterPage';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNamesBases } from './redux/getNetworkBaseNamesSlice';
import { nameSelect } from './redux/selectBaseNameSlice';
import { fetchBinZips } from './redux/getNetworkBinSlice';

function App() {
  const dispatch = useDispatch();
  const datanames = useSelector(state => state?.valuesNameBases.data);
  const nameBaseSelect = useSelector(state => state?.valuesNameBaseSelect?.selects?.name);
  
  useEffect(() => {
    dispatch(fetchNamesBases());
  }, [dispatch]);
  
  useEffect(() => {
    if(datanames) {
      dispatch(nameSelect({name: "<-all->"}))
    }
  }, [dispatch, datanames]);

  useEffect(() => {
    if(nameBaseSelect) {
      dispatch(fetchBinZips({name_base: nameBaseSelect}));
    }
  }, [dispatch, nameBaseSelect]);

  return (
    <div className="App">
      <header className="App-header">
      <CenterPage />
      </header>
    </div>
  );
}

export default App;
